<template>
  <!--begin::Layout-->
  <div v-if="contactQuestion" class="d-flex flex-column flex-lg-row">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0">
      <!--begin::Card-->
      <div class="card card-flush pt-3 mb-5 mb-xl-10">
        <!--begin::Card body-->
        <div class="card-body pt-3">
          <h2 class="fw-bolder me-6">Contact Question</h2>

          <div class="mb-10">
            <div class="mb-4">
              <label class="d-block">Title</label>
              <input
                v-model="contactQuestion.title"
                type="text"
                class="form-control"
                placeholder="Title"
              />
            </div>

            <div
              class="
                form-check form-switch form-check-custom form-check-solid
                mb-4
              "
            >
              <input
                class="form-check-input"
                type="checkbox"
                value="true"
                id="require-clinic"
                v-model="contactQuestion.requestClinic"
                @change="() => !contactQuestion.requestClinic ? contactQuestion.hotProspect = false : null"
              />
              <label class="form-check-label" for="require-clinic"
                >Require clinic selection</label
              >
            </div>

            <div
              class="
                form-check form-switch form-check-custom form-check-solid
                mb-4
              "
            >
              <input
                class="form-check-input"
                type="checkbox"
                id="hot-prospect"
                v-model="contactQuestion.hotProspect"
                @change="() => contactQuestion.hotProspect ? contactQuestion.requestClinic = true : null"
              />
              <label class="form-check-label" for="hot-prospect"
                >Hot prospect</label
              >
            </div>

            <div v-if="!contactQuestion.hotProspect" class="mb-4">
              <label class="d-block">Auto reply email Template</label>
              <select
                class="form-select"
                v-model="contactQuestion.autoResponseEmailTemplate"
              >
                <option
                  v-for="emailTemplate in emailTemplates"
                  :key="emailTemplate.id"
                  :value="emailTemplate.id"
                >
                  {{ emailTemplate.title }}
                </option>
              </select>
            </div>
          </div>

          <div class="separator separator-dashed mb-7"></div>

          <div
            v-for="question in contactQuestion.questions"
            :key="question.language"
          >
            <div class="mb-10">
              <div class="mb-4">
                <label class="d-block"
                  >Question ({{ question.language }})</label
                >
                <input
                  v-model="question.question"
                  type="text"
                  class="form-control"
                  :placeholder="`Question (${question.language})`"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Content-->

    <!--begin::Sidebar-->
    <div
      class="
        flex-column flex-lg-row-auto
        w-lg-250px w-xl-300px
        mb-10
        order-1 order-lg-2
      "
    >
      <!--begin::Card-->
      <div
        class="card card-flush mb-0"
        data-kt-sticky="true"
        data-kt-sticky-name="subscription-summary"
        data-kt-sticky-offset="{default: false, lg: '200px'}"
        data-kt-sticky-width="{lg: '250px', xl: '300px'}"
        data-kt-sticky-left="auto"
        data-kt-sticky-top="150px"
        data-kt-sticky-animation="false"
        data-kt-sticky-zindex="95"
      >
        <!--begin::Card header-->
        <div class="card-header">
          <div class="card-title">
            <h2>Actions</h2>
          </div>
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0 fs-6">
          <!--begin::Actions-->
          <div class="mb-0 d-grid">
            <!--begin::Save-->
            <div
              v-if="savingQuestion"
              class="btn btn-primary fs-bold me-4 disabled"
              disabled
            >
              <span class="disabled">
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                />
                Saving...
              </span>
            </div>
            <div
              v-else
              class="btn btn-primary fs-bold me-4"
              data-kt-inbox-form="send"
              @click="saveEmailTemplate"
            >
              <span class="indicator-label">Save</span>
            </div>
            <!--end::Save-->
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Section-->
      </div>
    </div>
    <!--end::Sidebar-->
  </div>
  <!--end::Layout-->
</template>

<script>
import ApiService from "@/core/services/ApiService";

export default {
  name: "ContactQuestion",
  data() {
    return {
      emailTemplates: [],
      contactQuestion: null,
      savingQuestion: false,

      // Attachments upload
      uploadFieldName: "attachments",
      isSaving: false,
    };
  },
  mounted() {
    ApiService.query("email-templates", { params: { limit: 0 } }).then(
      ({ data: { results } }) => {
        console.log(results);
        this.emailTemplates = [
          { id: null, title: "No auto reply" },
          ...results,
        ];
      }
    );

    if (this.$route.params.id) {
      ApiService.get("contact-questions", this.$route.params.id).then(
        ({ data }) => {
          this.contactQuestion = data;
        }
      );
    } else {
      this.contactQuestion = {
        title: "",
        requestClinic: false,
        questions: [
          {
            language: "FR",
            question: "",
          },
          {
            language: "EN",
            question: "",
          },
        ],
        autoResponseEmailTemplate: null,
      };
    }
  },
  methods: {
    cslg(v) {
      console.log(v);
    },
    addLanguage() {
      const language = prompt("Which language do you want to add ?");
      if (language) {
        this.contactQuestion.questions.push({
          language,
          question: "",
        });
        this.selectedTemplateIdx = this.contactQuestion.templates.length - 1;
      }
    },
    saveEmailTemplate() {
      this.savingQuestion = true;

      // No automatic response possible if a clinic is requested
      if (this.contactQuestion.hotProspect) {
        this.contactQuestion.autoResponseEmailTemplate = null;
      }

      if (this.contactQuestion.id) {
        const config = {
          responseToast: {
            text: "Question updated successfully.",
          },
        };
        ApiService.update(
          "contact-questions",
          this.contactQuestion.id,
          this.contactQuestion,
          config
        )
          .then(({ data }) => {
            this.contactQuestion = data;
            this.savingQuestion = false;
          })
          .catch((err) => {
            this.savingQuestion = false;
          });
      } else {
        const config = {
          responseToast: {
            text: "Question created successfully.",
          },
        };
        ApiService.post("contact-questions", this.contactQuestion, config)
          .then(({ data }) => {
            this.contactQuestion = data;
            this.savingQuestion = false;
            this.$router.replace({
              name: "contact-question",
              params: { id: this.contactQuestion.id },
            });
          })
          .catch((err) => {
            this.savingQuestion = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment-input-wrapper {
  position: relative;
  cursor: pointer;

  input[type="file"] {
    cursor: inherit;
    filter: alpha(opacity=0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: right;
  }
}

.dropzone.dropzone-queue .dropzone-item {
  word-break: break-all;
}
</style>
